import CityConfirmComponent from "./components/city-confirm-component";
import HeaderComponent from "./components/header-component";
import {DESCTOP_MENU_ITEMS} from "./const/menu-items";
import {initAuth} from "./init-auth";
import {initCart} from "./init-cart";
import {initFavorites} from "./init-favorites";
import {initSearch} from "./init-search";

import {renderContact} from "./render-contact";
import {renderNav} from "./render-nav";
import {setCity} from "./require/set-city";
import {showCatalogPopup} from "./show-catalog-popup";
import {showCitiesPopup} from "./show-cities-popup";
import {RenderPosition, render} from "./utils/render";
import MenuController from "./controllers/menu-controller";


export const renderHeader = (shopInfoData, logoData, catalogList, authCb) => {
  const container = document.querySelector(`.main-header`);
  if (!container) {
    return;
  }

  let menuController = null;

  const component = new HeaderComponent({logo: logoData});
  render(container, component.getElement(), RenderPosition.BEFOREEND);


  // обработчик скролла
  window.addEventListener(`scroll`, (evt) => {
    const scroll = evt.target.scrollingElement.scrollTop;
    if (scroll >= 200) {
      container.classList.add(`main-header--scroll`);
    } else {
      container.classList.remove(`main-header--scroll`);
    }
  });

  const onHideModalHanlder = component.onHideMenuHandler;
  // клик на бургер
  component.setBurgerButtonClickHanlder((isShowMenu) => {
    if (isShowMenu) {
      menuController = new MenuController({shopInfoData, logoData, catalogList, onHideModalHanlder});
      menuController.show();
    } else {
      if (menuController) {
        menuController.hide();
      }
      menuController = null;
    }
  });

  // клик по кнопка Каталога
  component.setCatalogButtonClickHandler(() => {
    showCatalogPopup(logoData, catalogList);
  });

  // отрисовка контакта
  const contactElement = component.getElement().querySelector(`.main-header__contact`);
  renderContact(contactElement, shopInfoData, logoData);
  if (shopInfoData.select_town && shopInfoData.require_town) {
    const mainPageElement = document.querySelector(`.main-page`);
    mainPageElement.dataset.isConfirmCity = `true`;
    // для десктопа
    const cityConfirmDesctopComponent = new CityConfirmComponent({isMobile: false, city: shopInfoData.town});
    cityConfirmDesctopComponent.setSelectCityButtonClickHandler(() => showCitiesPopup(logoData));
    cityConfirmDesctopComponent.setConfirmCityButtonClickHandler(() => {
      setCity({
        cityId: shopInfoData.shop_id,
      });
    });

    render(contactElement, cityConfirmDesctopComponent.getElement(), RenderPosition.BEFOREEND);

    // для мобилки
    const cityConfirmMobileComponent = new CityConfirmComponent({isMobile: true, city: shopInfoData.town});
    cityConfirmMobileComponent.setSelectCityButtonClickHandler(() => showCitiesPopup(logoData));
    cityConfirmMobileComponent.setConfirmCityButtonClickHandler(() => {
      setCity({
        cityId: shopInfoData.shop_id,
      });
    });

    render(component.getElement(), cityConfirmMobileComponent.getElement(), RenderPosition.BEFOREEND);
  }

  // отрисовка меню
  const navElement = component.getElement().querySelector(`.main-header__nav`);
  const listClassName = `nav__list`;
  const itemClassName = `nav__item`;
  const navItems = DESCTOP_MENU_ITEMS
      .filter((item) => !(item.id === `franchise` && !shopInfoData.show_fr)
      && !(item.id === `bonus` && !shopInfoData.show_bonus));

  if (shopInfoData.real_link) {
    navItems.unshift({
      id: `show_link`,
      title: ``,
      link: shopInfoData.real_link,
      svg: `<svg width="18" height="18" viewBox="0 0 24 24" role="presentation"><path d="M12.654 8.764a.858.858 0 01-1.213-1.213l1.214-1.214a3.717 3.717 0 015.257 0 3.714 3.714 0 01.001 5.258l-1.214 1.214-.804.804a3.72 3.72 0 01-5.263.005.858.858 0 011.214-1.214c.781.782 2.05.78 2.836-.005l.804-.803 1.214-1.214a1.998 1.998 0 00-.001-2.831 2 2 0 00-2.83 0l-1.215 1.213zm-.808 6.472a.858.858 0 011.213 1.213l-1.214 1.214a3.717 3.717 0 01-5.257 0 3.714 3.714 0 01-.001-5.258l1.214-1.214.804-.804a3.72 3.72 0 015.263-.005.858.858 0 01-1.214 1.214 2.005 2.005 0 00-2.836.005l-.804.803L7.8 13.618a1.998 1.998 0 00.001 2.831 2 2 0 002.83 0l1.215-1.213z" fill="currentColor"></path></svg>`,
    });
  }

  renderNav(navElement, navItems, listClassName, itemClassName);

  initAuth(authCb);
  initCart();
  initFavorites();
  initSearch();
};
