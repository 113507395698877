import {initFrPage} from "./init-fr-page";
import {initPage} from "./init-page";

window.addEventListener(`load`, () => {
  const authCb = () => {
    if (window.userSessionData) {
      const welcomeElement = document.querySelector(`.franchise-page__contentLead--welcome`);
      if (welcomeElement) {
        welcomeElement.classList.remove(`visuallyhidden`);
      }
    } else {
      const authButton = document.querySelector(`.franchise-page__authButton`);
      if (authButton) {
        authButton.classList.remove(`visuallyhidden`);
        authButton.addEventListener(`click`, () => {
          window.showAuthPopup();
        });
      }
    }
  };
  initPage(authCb);
  initFrPage();

});
