import {getLogoData} from "./data/logo";
import {onEscPressPopupHandler} from "./on-esc-press-popup-handler";
import {renderBreadcrumbs} from "./render-breadcrumbs";
import {renderFooter} from "./render-footer";
import {renderHeader} from "./render-header";

const getGroupData = (catalogList) => {
  const result = {};
  Object.keys(catalogList).forEach((key) => {
    if (key !== `current`) {
      const group = catalogList[key];
      result[group.id] = group;

      if (group.subgroup) {
        Object.values(group.subgroup).forEach((subgroup) => {
          result[subgroup.id] = subgroup;
        });
      }
    }
  });

  return result;
};

window.userSessionData = null;
window.groupData = null;


export const initPage = (authCb) => {
  const logoData = getLogoData(shopInfoData);

  renderHeader(shopInfoData, logoData, catalogList, authCb);
  renderBreadcrumbs();
  renderFooter(shopInfoData, logoData);

  const closePopupButtons = document.querySelectorAll(`.popup__closeButton`);

  Array.from(closePopupButtons).forEach((btn) => {
    btn.addEventListener(`click`, () => {
      const popup = btn.parentElement.parentElement;
      popup.classList.remove(`popup`, `popup--show`);
      // centerHeaderElement.classList.remove(`main-header__center--popup`);
      document.removeEventListener(`keydown`, onEscPressPopupHandler);
      document.querySelector(`body`).classList.remove(`overflowHidden`);
    });
  });

  const closeModalButton = document.querySelectorAll(`.modal__closeButton`);

  Array.from(closeModalButton).forEach((button) => {
    button.addEventListener(`click`, () => {
      const modal = button.parentElement.parentElement;
      modal.classList.remove(`modal--show`);
      document.removeEventListener(`keydown`, onEscPressModalHandler);
    });
  });

  const overlayModalButton = document.querySelectorAll(`.modal__overlay`);

  Array.from(overlayModalButton).forEach((overlay) => {
    overlay.addEventListener(`click`, () => {
      const modal = overlay.parentElement;
      modal.classList.remove(`modal--show`);
      document.removeEventListener(`keydown`, onEscPressModalHandler);
    });
  });

  const onEscPressModalHandler = (evt) => {
    if (evt.key === `Escape`) {
      const modalElements = document.querySelectorAll(`.modal--show`);
      modalElements.forEach((elem) => {
        elem.classList.remove(`modal--show`);
        document.removeEventListener(`keydown`, onEscPressModalHandler);
      });
    }
  };

  window.groupData = getGroupData(catalogList);
};
