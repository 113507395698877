import {getData} from "./api/get-data";
import {showAuthPopup} from "./show-auth-popup";
import {updateBonuses} from "./update-bonuses";

export const initAuth = (authCb = () => {}) => {
  const authorizationButton = document.querySelector(`.js--authorizationButton`);

  if (!authorizationButton) {
    return;
  }

  getData({
    [`get_header_user`]: ``,
  })
  .then((response) => {
    if (response.result === `OK`) {
      window.userSessionData = response;

      authorizationButton.querySelector(`span`).innerHTML = window.userSessionData.user_name;

      authorizationButton.addEventListener(`click`, () => {
        document.location.href = `${window.location.origin}/login`;
      });

      if (document.querySelector(`.cart-page__bonusCount`)) {
        updateBonuses();
      }
    }

    if (response.result === `NO_AUTH`) {
      authorizationButton.querySelector(`span`).innerHTML = `Войти`;

      authorizationButton.addEventListener(`click`, () => {
        showAuthPopup();
      });
    }

    if (document.querySelector(`.order-page`) && !window.userSessionData) {
      showAuthPopup();
    }

    authCb();
  });
};
